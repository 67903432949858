import React from "react"
import "@brainhubeu/react-carousel/lib/style.css"
import SingleLocation from "./singleLocation"

import { BrowserView, MobileView } from "react-device-detect"
import Carousel from "@brainhubeu/react-carousel"
import { useStaticQuery, graphql } from "gatsby"

export default function Locations() {
  let data = useStaticQuery(graphql`
    query IndexLocation {
      allAirtable(
        filter: { data: { section: { eq: "Ubicacion" }, data: {} } }
      ) {
        nodes {
          table
          data {
            Name
            data
            data2
            data3
            data4
            data5
            img {
              thumbnails {
                full {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  let locations = []

  data.allAirtable.nodes.map((node, index) => {
    if (index % 2 === 0) {
      locations.push({
        backgroundColor: "#3E4B54",
        city: node.data.data,
        direction: node.data.data2,
        phone: node.data.data3,
        email: node.data.data4,
        bannerDescription: node.data.data5,
        backgroundImage: node.data.img[0].thumbnails.full.url,
      })
    } else {
      locations.push({
        backgroundColor: "#249B88",
        city: node.data.data,
        direction: node.data.data2,
        phone: node.data.data3,
        email: node.data.data4,
        bannerDescription: node.data.data5,
        backgroundImage: node.data.img[0].thumbnails.full.url,
      })
    }

    return null
  })

  return (
    <div>
      <BrowserView>
        <Carousel
          infinite
          animationSpeed={1500}
          autoPlay={3000}
          slidesPerPage={3}
        >
          {locations.map(location => {
            return <SingleLocation {...location} />
          })}
        </Carousel>
      </BrowserView>
      <MobileView>
        <Carousel infinite animationSpeed={1500} autoPlay={3000}>
          {locations.map(location => {
            return <SingleLocation {...location} />
          })}
        </Carousel>
      </MobileView>
    </div>
  )
}
