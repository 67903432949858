import React from "react"
import SingleLocationStyles from "./singleLocation.module.scss"
import ImgPhone from "../../images/12.png"
import ImgEmail from "../../images/13.png"

export default function SingleLocations(props) {
  return (
    <div
      className={SingleLocationStyles.singlecontainer}
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className={SingleLocationStyles.singlehelper}>
        <h2 className={SingleLocationStyles.singletitle}>{props.city}</h2>
        <div className={SingleLocationStyles.singlecontainerDescription}>
          <div></div>
          <p className={SingleLocationStyles.singledescription}>
            {props.direction}
          </p>

          <img
            className={SingleLocationStyles.singleimg}
            alt="phone"
            src={ImgPhone}
          />
          <p className={SingleLocationStyles.singledescription}>
            {props.phone}
          </p>

          <img
            className={SingleLocationStyles.singleimg}
            alt="email"
            src={ImgEmail}
          />
          <p className={SingleLocationStyles.singledescription}>
            {props.email}
          </p>
        </div>
      </div>
      <div
        className={SingleLocationStyles.singlemap}
        style={{ backgroundImage: `url(${props.backgroundImage})` }}
      >
        <div
          className={SingleLocationStyles.singlebannerContainer}
          style={{ backgroundColor: props.backgroundColor }}
        >
          <p className={SingleLocationStyles.singledescriptionBanner}>
            {props.bannerDescription}
          </p>
        </div>
      </div>
    </div>
  )
}
