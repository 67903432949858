import React from "react"
import Layout from '../components/layout/layout'
import Locations from "../components/home/locations"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Locations"
      description="¡Nuestro contacto dependiendo la cuidad!"
      // image={`https://www.ppdc.mx${blog}`}
      url="/contacto"
    />
    <Locations />
  </Layout>
)
